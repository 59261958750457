import getCookie from '@/utils/get-cookie';
import cookies from '@/utils/cookies';
export const getCookieConsent = () => {
    const value = getCookie(cookies.cookieDialog);
    if (typeof value !== 'string')
        return null;
    try {
        const result = JSON.parse(value);
        if (!('token' in result)) {
            return null;
        }
        if (('analytic' in result && typeof result.analytic === 'boolean') ||
            ('marketing' in result && typeof result.marketing === 'boolean')) {
            return {
                analytic: !!result.analytic,
                marketing: !!result.marketing,
                token: result.token,
            };
        }
    }
    catch (e) {
        if (e instanceof SyntaxError)
            return null;
        throw e;
    }
    return null;
};
export const convertCookieConsentIntoCategories = (consent) => {
    if (!consent) {
        return [];
    }
    if ('universal' in consent) {
        return ['analytic', 'marketing'];
    }
    return [
        ...(consent.analytic ? ['analytic'] : []),
        ...(consent.marketing ? ['marketing'] : []),
    ];
};
