import { getCookieConsent } from '@/utils/get-cookie-consent';
import manageScripts from '@/utils/manage-scripts';
import { getScriptAttribute } from '@/utils/script-attributes';
const render = (initScreen) => {
    import('./CookieConsentDialog').then((result) => result.renderCookieConsent(initScreen));
};
const zIndex = getScriptAttribute('zIndex');
const main = () => {
    const currentCookie = getCookieConsent();
    if (!currentCookie) {
        if (getScriptAttribute('dialog', 'true') === 'true') {
            render({
                type: 'dialog',
                position: getScriptAttribute('position'),
                zIndex,
            });
        }
    }
    else {
        manageScripts();
    }
};
window.cookieManager = {
    openSettings: () => {
        render({ type: 'settings', zIndex });
    },
};
main();
